import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const RefundPolicyPage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Supertone Refund Policy</h1>

      <p>
        Since our products are all digital goods we cannot revoke access and
        thus not grant any refunds. Please use the free trial to make sure you
        really need the product before purchasing.
      </p>
    </div>
  );
};

export default RefundPolicyPage;
